const firebaseConfig = {
  projectId: "the-valour",
  appId: "1:577501583321:web:d9bd9908b7c476a3e6abc8",
  databaseURL:
    "https://the-valour-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-valour.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyBE4T7vdzvM2nxt0WC6dxzBheVKGhkGUlc",
  authDomain: "the-valour.firebaseapp.com",
  messagingSenderId: "577501583321",
  measurementId: "G-RT5DKH6961",
};

export default firebaseConfig;
